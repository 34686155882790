.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    padding-top: 4%;
    box-sizing: border-box; /* Include padding in width and height calculations */
    max-height: 100%;
}

.modal img {
    height: auto;
    width: 100%;
    max-height: 80vh;
    display: block;
    margin: auto;
    object-fit: contain;
    justify-self: center;
    vertical-align: middle;
}

#aboutModal, #iWantThisModal, #collaborateModal {
    box-sizing: border-box;
    display: none;
    flex-grow: 1;
    position: sticky !important;
    width: 100% !important;
    z-index: 2000 !important;
    background-color: white !important;
    /*height: 80vh !important;*/
    font-weight: normal;
    font-size: 22px;
    font-family: monospace;
}

.heading2 {
    font-family: sans-serif !important;
}

.about-text, .i-want-this-text {
    /*text-indent: 2em;*/
}

.text-muted {
    font-size: 0.65em !important;
}

.form-control, .email-submit-button {
    font-size: 1em !important;
}

#iWantThisModalSubmit, #collaborateModalSubmit {
    padding-bottom: 0.5em;
}

.nav-link {
    width: 100%;
    height: 100%;
}

html {
    background-color: #ffffff;
}

:root {
    --titleContainerBackgroundColor: white;
}

.container-fluid.title-container {
    background-color: var(--titleContainerBackgroundColor); /* Example color */
    display: flex; /* Enable Flexbox */
    align-items: center; /* Align */
    justify-content: center;
    padding-top: .75em;
    padding-bottom: 0.65em;
    margin-bottom: 0;
    color-scheme: only light;
    position: sticky;
    flex-shrink: 0;
}

.modal-close {
    font-size: 15px;
    transform: scale(1.3, 1);
    z-index: 2000;
    color: #ffffff;
    display: none;
    background-color: #000000;
    border: #000000;
}

.imageModalArrowButtons {
    padding-top: 2em;
}

#imageModalLeftArrow {
    /*font-size: 25px;*/
    z-index: 2000;
    color: #ffffff;
    background: none;
    border: #000000;
    transform: scale(3, 3);

    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 90%;
}

#imageModalRightArrow {
    /*font-size: 25px;*/
    transform: scale(3, 3);
    z-index: 2000;
    color: #ffffff;
    background: none;
    border: #000000;

    position: absolute;
    left: 50%;
    margin-left: 20px;
    top: 90%;
}

#grid {
    display: flex;
    flex-direction: column;
    height: 97vh; /* Adjust as necessary */
}

#container {
    flex-grow: 1; /* Allows it to fill available space */
    overflow-y: auto; /* Add scroll if content is too long */
    padding-bottom: 5rem;
    position: relative;
}

#scroll-area-buffer {
    height: 2em;
    display: inline-block;
}

.nav-item:hover {
    cursor: pointer;
}

#container-footer {
    background-color: var(--titleContainerBackgroundColor); /* Example color */
    color: #000000;
    font-size: 1.5em;
    text-align: center;
    /* Ensure it stays at the bottom */
    flex-shrink: 0;
    display: block;
    flex-direction: column;
    position: static;
    .copyright-note {
        font-size: 1rem !important;
        font-family: sans-serif !important;
    }
}

.container {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.thumbnail-img {
    padding: 0 !important;
    margin: 0 !important;
}

.row {
    margin: 0 !important;
    margin-left: -16px !important;
    padding: 0 !important;
}

.thumbnail-img:hover {
    transform: scale(1.1); /* 10% larger */
    transition: transform 0.2s ease-in-out; /* Smooth transition */
    outline: 5px solid #e8b50f;
    border-color: #e8b50f;
    box-shadow: 0 0 10px #121b21;
}

.App-logo {
    width: 10em;
    aspect-ratio: 1;
    margin-left: -9%;
}
#emerald-caress {
    font-size: 8em;
    background-color: var(--titleContainerBackgroundColor); /* Example color */
    z-index: 1000;
}

.nav-link {
    color: white !important;
    font-size: 1.5em !important;
}

.nav-item:hover {
    background-color: #e8b50f !important;
    color: black !important;
    height: 100%;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    border-color: white !important;
}

.navbar-toggler-icon:active {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(232,181,15, 100)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    border-color: white !important;
}

@media screen and (max-width: 1099px) {
    .App-logo {
        width: 2.75rem;
        aspect-ratio: 1;
    }
    #emerald-caress {
        padding-top: .75rem !important;
        padding-bottom: .35rem !important;
        font-size: 1.95rem;
        background-color: var(--titleContainerBackgroundColor);
        z-index: 1005;
    }
    .nav-link {
        font-size: 1em !important;
        width: fit-content;
        margin-left: 1.6%;
    }

    #grid {
        display: flex;
        flex-direction: column;
        height: 95vh; /* Adjust as necessary */
    }

    #container-footer {
        /*max-height: 1.5em;*/
        max-height: fit-content;
        .copyright-note {
            font-size: 0.60rem !important;
        }
    }
    .container{
        margin: 0 !important;
        /*margin-top: -1% !important;*/
        /*margin-bottom: 8%;*/
        max-width: 100% !important;
        padding-top: 0 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        .thumbnail-row {
            max-width: 100% !important;
            padding: 0 !important;
            margin: 0 !important;
            .thumbnail-img {
                width: 33.33% !important;
                aspect-ratio: 1;
                object-fit: cover;
            }
        }
    }

    .container-fluid.title-container {
        padding-bottom: 0;
        padding-top: 0;
    }

    #aboutModal, #iWantThisModal, #collaborateModal {
        font-weight: normal;
        font-size: 12px;
        font-family: monospace;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
}


@media screen and (min-width: 1100px) and (max-width: 1399px) {
    .container{
        max-width: 1400px;
        .thumbnail-row {
            max-width: 1300px !important;
        }
        .thumbnail-img {
            width: 300px !important;
            height: 300px !important;
            object-fit: cover;
        }
    }

    #aboutModal, #iWantThisModal, #collaborateModal {
        width: 900px !important;
        margin-left: 2.4% !important;
    }
}

@media  screen and (min-width: 1400px) {
    .nav-item:active {
        background-color: #3e8e41;
        transform: scale(0.8, 0.8);
    }
    #navbar {
        max-width: 1200px;
        margin-left: 3.1% !important;
        padding-left: 0 !important;
        z-index: 999 !important;
    }

    .container{
        max-width: 1200px;
        .thumbnail-img {
            width: 400px !important;
            height: 400px !important;
            object-fit: cover;
        }
    }

    #aboutModal, #iWantThisModal, #collaborateModal {
        width: 1200px !important;;
        margin-left: 2.4% !important;
    }
}

.last-original-img {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
}
